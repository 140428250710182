.AdminSettings {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);

  .AdminSettings--settings__close {
    display: flex;
    width: 50px;
    margin-left: auto;
    margin-right: 0;
    &:hover path {
      fill: rgba(0,0,0,0.85);
    }
    path {
      fill: rgba(0,0,0,1);
    }
  }
}


.Clock {
  position: relative;
  width: 100%;
  height: 100%;
}

.Clock--content {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  background: blue;
  color: var(--contrast-color);
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  .container {
    padding: 0;
  }
  #name {
    margin-top: 0;
  }
  .Clock--footer {
    font-size: 0.8em;
    color: var(--contrast-color);
    a {
      color: var(--contrast-color);
      &:hover {
        color: var(--contrast-color-transparent);
      }
    }
  }
}

.Clock--time {
  margin: 0;
}

.Clock--cog {
  cursor: pointer;
  display: flex;
  margin-top: .8em;
  margin-right: 0;
  margin-left: auto;
  
  &:hover path {
    fill: var(--contrast-color-transparent);
  }
  path {
    fill: var(--contrast-color);
  }
}

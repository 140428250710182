:root {  
  --r: 46;
  --g: 122;
  --b: 228;

  --threshold: 0.5;
  /* calculates perceived lightness using the sRGB Luma method */
  --rLuma: calc(var(--r) * 0.2126);
  --gLuma: calc(var(--g) * 0.7152);
  --bLuma: calc(var(--b) * 0.0722);
  --sum: calc(var(--rLuma) + var(--gLuma) + var(--bLuma));
  --perceived-lightness: calc(var(--sum) / 255);
  
  --main-color: rgb(var(--r), var(--g), var(--b));
  /* shows either white or black color depending on perceived darkness */
  --contrast-color: hsl(0, 0%, calc((var(--perceived-lightness) - var(--threshold)) * -10000000%));
  --contrast-color-transparent: hsla(0, 0%, calc((var(--perceived-lightness) - var(--threshold)) * -10000000%), 0.8);

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

.container {
  margin: 30px auto;
  max-width: 750px;
  padding: 20px;
}

@media (max-width: 750px) {
  .container {
    margin: 0;
  }
}

button {
  padding: .375rem .75rem;
  line-height: 1.5;
  border-radius: 4px;
  font-size: 1em;
  transition: background-color 300ms;

  &.primary {
    background-color: rgb(255, 255, 255);
    &:hover {
      background-color: rgb(247, 247, 247);
    }
  }
  
  &.danger {
    color: white;
    background-color: #FF0000;
    &:hover {
      background-color: rgb(219, 0, 0);
    }
  }
}
.Calendars {
  .Calendars--calendar {
    display: flex;
    padding: 15px;
    h4 {
      margin: 0;
    }
    input {
      margin: 0 0 0 auto;
    }
  }
  button {
    margin-top: 20px;
    margin-right: 20px;
  }
}
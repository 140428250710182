.Settings {  
  // Toggled to block
  display: none;

  position: absolute;
  top: 0;
  left: 0;

  z-index: 2;
  box-sizing: border-box;
  min-height: 100%;
  width: 100vw;

  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(5px);

  // padding: 20px;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .react-toggle--checked {
    .react-toggle-track {
      background-color: var(--main-color);
    }
    
    &:hover .react-toggle-track {
      background-color: var(--main-color);
    }
  }

  .Settings--close {
    cursor: pointer;
    width: 36px;
    height: 36px;
    &:hover path {
      fill: rgba(255,255,255,0.8);
    }
  }

  .Settings--setting {
    padding: 10px 0;
    > span {
      padding-right: 10px;
    }
  }
}
.ProgressBar {
  width: 100%;
  border: 3px solid white;
  border: 3px solid var(--contrast-color);
}

.ProgressBar--progress {
  height: 20px;
  background: white;
  background: var(--contrast-color-transparent);
}
.Dashboard {
  background-color: rgb(203, 225, 255);
  label {
    display: block;
    margin: 5px 0;
  }
  input[type="text"] {
    line-height: 1.5;
    font-size: 1em;
    padding: .375rem .75rem;
    margin-right: 3px;
    border-radius: 4px;
    border: none;
  }
  .Dashboard--clock {
    display: flex;
    margin: 10px 0;
    p {
      display: inline-block;
      margin: 0;
    }
    a {
      height: 1.2em;
    }
    .cog {
      height: 1.2em;
    }
  }
  .Dashboard--clockLink {
    color: inherit;
  }
}